var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    attrs: {
      "app": "",
      "clipped-left": "",
      "color": "primary",
      "dark": ""
    }
  }, [_c('v-img', {
    staticClass: "shrink mr-2",
    attrs: {
      "alt": "Logo",
      "contain": "",
      "src": "/static/logo.png",
      "transition": "scale-transition",
      "max-width": "40"
    }
  }), _c('v-toolbar-title', [_vm._v(_vm._s(_vm.title))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("退出")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }