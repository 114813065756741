export const categories = (target) => {
  let objTarget = {}, dataTime = new Date()
  switch (target) {
    case 'permission':
      objTarget = [
        { title: '工作台 > 设备服务', name: 'Service_Edit', icon: 'assignment' },
        { title: '工作台 > 维修服务', name: 'Maintain_Edit', icon: 'assignment' },
        { title: '工作台 > 咨询服务', name: 'Advisory_Edit', icon: 'assignment' },
        { title: '统计报告 > 月度报告', name: 'Report_Publish', icon: 'analytics' },
        { title: '企业管理', name: 'Enterprise_Edit', icon: 'apartment' },
        { title: '企业授信', name: 'Enterprise_Risk_Approval', icon: 'release' },
        { title: '企业员工', name: 'Enterprise_Employee', icon: 'supervisor_account' },
        { title: '设备管理', name: 'Asset_Manage', icon: 'devices' },
        { title: '订单服务 > 客户销售', name: 'Order_Sale', icon: 'assignment' },
        { title: '订单服务 > 专员', name: 'Order_Create', icon: 'assignment' },
        { title: '订单服务 > 管理员', name: 'Order_Service', icon: 'assignment' },
        { title: '外部采购 > 专员', name: 'Purchase_Service', icon: 'assignment' },
        { title: '外部采购 > 管理员', name: 'Purchase_Admin', icon: 'assignment' },
        { title: '发货管理 > 管理员', name: 'Delivery_Admin', icon: 'local_shipping' },
        { title: '发货管理 > 申请', name: 'Delivery_Create', icon: 'local_shipping' },
        { title: '租赁服务 > 报价计算', name: 'Lease_Sale', icon: 'cast_connected' },
        { title: '租赁服务 > 报价审核', name: 'Lease_Audit', icon: 'cast_connected' },
        { title: '租赁服务 > 产品管理', name: 'Lease_Product', icon: 'cast_connected' },
        { title: '租赁服务 > 风控管理', name: 'Lease_Risk', icon: 'cast_connected' },
        { title: '租赁服务 > 管理员', name: 'Lease_Admin', icon: 'cast_connected' },
        { title: '网站首页', name: 'CMS_Page', icon: 'line_style' },
        { title: '素材管理', name: 'CMS_Material', icon: 'layers' },
        { title: '流程管理 > 流程配置', name: 'System_Workflow', icon: 'settings' },
        { title: '系统设置 > 组织权限', name: 'System_Organize', icon: 'settings' },
        { title: '系统设置 > 分支机构', name: 'System_Branch', icon: 'settings' },
        { title: '系统设置 > 服务目录', name: 'System_Catalog', icon: 'settings' },
        { title: '系统设置 > 知识目录', name: 'System_Knowledge', icon: 'settings' },
        { title: '系统设置 > 资产配置', name: 'System_Asset', icon: 'settings' },
        { title: '系统设置 > 产品管理', name: 'System_Product', icon: 'settings' },
        { title: '系统设置 > 风控管理员', name: 'System_RiskAdmin', icon: 'settings' },
        { title: '系统设置 > 风控成员', name: 'System_RiskStaff', icon: 'settings' },
        { title: '系统设置 > 自定义字段', name: 'System_Fields', icon: 'settings' },
        { title: '系统设置 > 操作日志', name: 'System_Logs', icon: 'settings' },
        { title: '系统设置 > 问题反馈', name: 'System_Bugs', icon: 'settings' },
        { title: '系统设置 > 管理工具', name: 'System_Tools', icon: 'settings' },
        { title: '接口集成 > 创建工单', name: 'API_Case', icon: 'api' },
        { title: '接口集成 > 查询工单', name: 'API_Case_List', icon: 'api' },
        { title: '接口集成 > 批量创建维修工单', name: 'API_Repair', icon: 'api' },
        { title: '接口集成 > 批量创建租赁订单', name: 'API_RentOrder', icon: 'api' },
        { title: '接口集成 > 订单查询', name: 'API_Order_List', icon: 'api' },
        { title: '接口集成 > 订单详情', name: 'API_Order_Details', icon: 'api' },
        { title: '接口集成 > 创建企业', name: 'API_Enterprise', icon: 'api' },
        { title: '接口集成 > 单点登录', name: 'API_SSO', icon: 'api' },
        { title: '接口集成 > 设备物流', name: 'API_Transport', icon: 'api' },
        { title: '接口集成 > 服务目录', name: 'API_ServiceList', icon: 'api' },
        { title: '接口集成 > 全部知识查阅', name: 'API_KM_ALL', icon: 'api' },
        { title: '接口集成 > 用户知识查阅', name: 'API_KM_EXN', icon: 'api' },
        { title: '接口集成 > 授信查询', name: 'API_CreditLine', icon: 'api' },
        { title: '移动端 > 预授信', name: 'Mobile_Creditline_PreApply', icon: 'smartphone' },
        { title: '移动端 > 授信申请', name: 'Mobile_Creditline_Apply', icon: 'smartphone' },
        { title: '移动端 > 企业授信卡', name: 'Mobile_Creditline_Query', icon: 'smartphone' },
        { title: '移动端 > 授信审批列表', name: 'Mobile_Approval_Review', icon: 'smartphone' },
        { title: '移动端 > 授信审批详情', name: 'Mobile_Approval_Review_Detail', icon: 'smartphone' },
        // { title: '页面测试', name: 'test', icon: 'settings' }
      ]
      break
    case 'deliver':
      objTarget = [
        { name: "服务门店", code: 1 },
        { name: "内部团队", code: 2 },
        { name: "下属单位", code: 3 },
        { name: "外包团队", code: 4 },
      ]
      break
    case 'corpType':
      objTarget = [
        { name: "民营", code: 1 },
        { name: "外资", code: 2 },
        { name: "合资", code: 3 },
        { name: "事业单位", code: 4 },
      ]
      break
    case 'SLAExpiry': {
      objTarget = [
        { name: '已过期', code: dataTime.setDate(dataTime.getDate()) },
        { name: '2小时内', code: dataTime.setTime(dataTime.getTime() + (2 * 3600 * 1000)) },
        { name: '4小时内', code: dataTime.setTime(dataTime.getTime() + (4 * 3600 * 1000)) },
        { name: '8小时内', code: dataTime.setTime(dataTime.getTime() + (8 * 3600 * 1000)) },
        { name: '1天内', code: dataTime.setDate(dataTime.getDate() + 1) },
        { name: '2天内', code: dataTime.setDate(dataTime.getDate() + 2) },
      ]
      break
    }
    case 'system3RD': {
      objTarget = [
        { name: '企业微信', code: 'wechat'},
        { name: '钉钉', code: 'dingding'},
        { name: '飞书', code: 'feishu'},
      ]
      break
    }
    case 'expiry': {
      objTarget = [
        { name: '已过期', code: dataTime.setDate(dataTime.getDate()) },
        { name: '1天内', code: dataTime.setDate(dataTime.getDate() + 1) },
        { name: '1周内', code: dataTime.setDate(dataTime.getDate() + 7) },
        { name: '2周内', code: dataTime.setDate(dataTime.getDate() + 14) },
        { name: '1个月内', code: dataTime.setDate(dataTime.getDate() + 30) },
      ]
      break
    }
    case 'updateTime': {
      objTarget = [
        { name: '1天内', code: dataTime.setDate(dataTime.getDate() - 1) },
        { name: '1周内', code: dataTime.setDate(dataTime.getDate() - 7) },
        { name: '2周内', code: dataTime.setDate(dataTime.getDate() - 14) },
        { name: '1个月内', code: dataTime.setDate(dataTime.getDate() - 30) },
      ]
      break
    }
    case 'inputRules':
      objTarget = [
        { name: '必须填写', code: 'required' },
        { name: '必须选择', code: 'selected' },
        { name: '电子邮件', code: 'email' },
        { name: '手机号码', code: 'phonenum' },
      ]
      break
    case 'materialType':
      objTarget = [
        { name: '文章', code: 1 },
        // { name: '图片', code: 2 },
        // { name: '音乐', code: 3 },
        // { name: '视频', code: 4 },
        { name: '外部链接', code: 5 },
      ]
      break
    case 'action':
      objTarget = [
        { name: '注册', code: 'Register' },
        { name: '登录', code: 'Login' },
        { name: '注销', code: 'Logout' },
        // { name: '搜索', code: 'Search' },
        // { name: '获取', code: 'Read' },
        { name: '创建', code: 'Create' },
        { name: '编辑', code: 'Update' },
        { name: '批量', code: 'Batch' },
        { name: '删除', code: 'Delete' },
      ]
      break
    case 'objType':
      objTarget = [
        { name: '账户', code: 'Account' },
        { name: '经销商', code: 'Dealer' },
        { name: '企业', code: 'Enterprise' },
        { name: '部门', code: 'Department' },
        { name: '自定义字段', code: 'Field' },
        { name: '角色分组', code: 'Group' },
        { name: '角色', code: 'Roles' },
        { name: '分支机构', code: 'Branch' },
        { name: '服务目录', code: 'Catalog' },
        { name: '服务协议', code: 'Service' },
        { name: '素材', code: 'Material' },
        { name: '仓库', code: 'Warehouse' },
        { name: '供应商', code: 'Vendor' },
        { name: '产品分类', code: 'ProductType' },
        { name: '产品', code: 'Product' },
      ]
      break
    case 'ramSize':
      objTarget = [
        { name: '无', code: 0 },
        { name: '4G', code: 4 },
        { name: '8G', code: 8 },
        { name: '16G', code: 16 },
        { name: '32G', code: 32 },
        { name: '64G', code: 64 },
        { name: '128G', code: 128 },
        { name: '256G', code: 256 },
        { name: '512G', code: 512 },
        { name: '1T', code: 1024 },
        { name: '2T', code: 2048 },
      ]
      break
    case 'networkModel':
      objTarget = [
        { name: '蜂窝网络', code: '4G' },
        { name: '无线局域网', code: 'WIFI' },
      ]
      break
    case 'assetType':
      objTarget = [
        { name: '服务部署', code: 1 },
        { name: '礼品赠送', code: 2 },
      ]
      break
    case 'assetState':
      objTarget = [
        { name: '良好', code: 1 },
        { name: '损坏', code: 2 },
        { name: '磕碰', code: 3 },
        { name: '屏幕划痕', code: 4 },
        { name: '碎屏', code: 5 },
        { name: '花屏', code: 6 },
        { name: '机身变形', code: 7 },
        { name: '按键失灵', code: 8 },
        { name: '无法开机', code: 9 },
        { name: '私拆', code: 91 },
        { name: '无法激活', code: 92 },
        { name: '激活锁', code: 93 },
        { name: '浸液', code: 94 },
        { name: '其他功能性故障', code: 95 },
      ]
      break
    case 'guaranteedType':
      objTarget = [
        { name: '无', code: 0 },
        { name: 'Apple Care', code: 1 },
        { name: '倍升保险', code: 2 },
        { name: '保外', code: 3 },
        { name: '其它', code: 4 },
      ]
      break
    case 'taskRelation':
      objTarget = [
        { name: '串行', code: 'Sequence', text: '所有任务依次进行，一个任务完成后触发后续任务。' },
        // { name: '并行', code: 'Synchronization', text: '所有任务同时进行，全部完成后进入结束环节。' },
        // { name: '完成其一', code: 'Choice', text: '完成任务清单中任何一项后进入结束环节。' },
      ]
      break
    case 'serviceType':
      objTarget = [
        { name: '设备管理服务', code: 'asset_service', tip: '设备管理服务是ESP核心服务，企业与经销商签订服务协议后可以选择使用具体的服务流程。'},
        { name: '设备维修服务', code: 'asset_repair', tip: '设备维修服务是技术部门为企业提供远程及进厂维修更换备件的服务。'},
        { name: '问题咨询服务', code: 'consulting_service', tip: '问题咨询服务是一个远程服务，由企业方人员发起问题咨询，经销商方进行解答的过程。'},
        { name: '租赁服务', code: 'hire_service', tip: '租赁服务是一种协助企业快速完成办公条件的服务，可以降低企业运营成本提高工作效率。'},
      ]
      break
    case 'expressCorp':
      objTarget = [
        { name: '顺丰速运', code: 'shunfeng' },
        { name: '圆通速递', code: 'yuantong' },
        { name: '韵达快递', code: 'yunda' },
        { name: '中通快递', code: 'zhongtong' },
        { name: 'EMS', code: 'ems' },
        { name: '邮政快递包裹', code: 'youzhengguonei' },
        { name: '邮政标准快递', code: 'youzhengbk' },
        { name: '京东物流', code: 'jd' },
        { name: '申通快递', code: 'shentong' },
        { name: '百世快递', code: 'huitongkuaidi' },
        { name: '极兔速递', code: 'jtexpress' },
      ]
      break
    case 'expressRepair':
      objTarget = [
        { name: 'OSC 送回', code: 'osc' },
        { name: '顺丰速运', code: 'shunfeng' },
        { name: 'EMS', code: 'ems' },
        { name: '自提', code: 'self' },
      ]
      break
    case 'handoverType':
      objTarget = [
        { name: '快递', code: 'express'},
        { name: '上门', code: 'onsite'},
        { name: '网点', code: 'store'},
      ]
      break
    case 'recoveryType':
      objTarget = [
        { name: '送修', code: 'self'},
        // { name: '上门', code: 'store'},
        { name: '寄修', code: 'express'},
      ]
      break
    case 'returnType':
      objTarget = [
        {name: '快递寄回', code: 'express'},
        {name: '自提', code: 'self'}
      ]
      break
    case 'caseStatus':
      objTarget = [
        { name: '待受理', code: '0'},
        { name: '处理中', code: '1'},
        { name: '已取消', code: '2'},
        { name: '已完成', code: '4'},
      ]
      break
    case 'repairStatus':
      objTarget = [
        { name: '待受理', code: '0'},
        { name: '检测中/维修中', code: '1'},
        { name: '维修完成', code: '2'},
        { name: '已返回/已取机', code: '3'},
        { name: '服务结束', code: '4'},
        { name: '取消维修', code: '8'},
      ]
      break
    case 'orderStatus':
      objTarget = [
        { name: '已接单', code: '0'},
        { name: '处理中', code: '1'},
        { name: '已取消', code: '2'},
        { name: '已拒绝', code: '3'},
        { name: '已完成', code: '4'},
      ]
      break
    case 'purchaseStatus':
      objTarget = [
        { name: '待处理', code: '0'},
        { name: '处理中', code: '1'},
        { name: '已取消', code: '2'},
        { name: '已完成', code: '4'},
      ]
      break
    case 'assetFields':
      objTarget = [
        // { name: '设备名称', field: 'name', model: 'asset', format: { type: 'text', source: '', isMultiple: false  } },
        { name: '设备状态', field: 'state', model: 'asset', format: { type: 'select', source: 'AssetState', isMultiple: false } },
        { name: '设备序列号', field: 'serial_number', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: '设备标签', field: 'label', model: 'asset', format: { type: 'label', source: '', isMultiple: true } },
        { name: '外部编号', field: 'out_number', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        // { name: '产品品牌、类型...', field: 'vendor', model: 'asset', format: { type: 'select', source: 'Vendor', isMultiple: false } },
        // { name: '产品类型', field: 'product_type', model: 'asset', format: { type: 'select', source: 'ProductType', isMultiple: false } },
        { name: '产品', field: 'product', model: 'asset', format: { type: 'select', source: 'Product', isMultiple: false } },
        { name: '购买日期', field: 'purchase_time', model: 'asset', format: { type: 'datetime', source: '', isMultiple: false } },
        { name: '厂商质保期', field: 'warranty_time', model: 'asset', format: { type: 'number', source: '', isMultiple: false } },
        { name: '质保到期', field: 'warranty_deadline', model: 'asset', format: { type: 'datetime', source: '', isMultiple: false } },
        { name: '所在仓库', field: 'warehouse', model: 'asset', format: { type: 'text', source: 'Warehouse', isMultiple: false } },
        { name: '入库日期', field: 'warehouse_time', model: 'asset', format: { type: 'datetime', source: '', isMultiple: false } },
        // { name: '使用人员', field: 'user', model: 'asset', format: { type: 'object', source: '', isMultiple: false } },
        { name: '设备来源', field: 'asset_from', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: '设备情况', field: 'asset_state', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: '延保类型', field: 'guaranteed_type', model: 'asset', format: { type: 'number', source: '', isMultiple: true } },
        { name: '延保开始时间', field: 'guaranteed_time', model: 'asset', format: { type: 'datetime', source: '', isMultiple: false } },
        { name: '延保期限', field: 'guaranteed_limit', model: 'asset', format: { type: 'number', source: '', isMultiple: false } },
        { name: '延保到期', field: 'guaranteed_deadline', model: 'asset', format: { type: 'datetime', source: '', isMultiple: false } },
        { name: 'Apple ID', field: 'apple_id', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: 'Apple 密码', field: 'apple_password', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: '是否ABM', field: 'isABM', model: 'asset', format: { type: 'boolean', source: '', isMultiple: false } },
        { name: 'SIM卡已实名', field: 'sim_verified', model: 'asset', format: { type: 'boolean', source: '', isMultiple: false } },
        { name: 'SIM卡号', field: 'sim_code', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: 'MAC地址', field: 'mac_address', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: 'ICCID串号', field: 'iccid_code', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: 'IMEI编号', field: 'imei_number', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: 'MDM Token', field: 'mdm_token', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: 'MDM 账号', field: 'mdm_account', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: 'MDM 密码', field: 'mdm_password', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: '设备备注', field: 'remarks', model: 'asset', format: { type: 'textarea', source: '', isMultiple: false } },
        { name: '客户付款日', field: 'rent_info.payment_day', model: 'asset', format: { type: 'select', source: '', isMultiple: false } },
        { name: '起租日期', field: 'rent_info.lease_start', model: 'asset', format: { type: 'datetime', source: '', isMultiple: false } },
        { name: '租赁到期时间', field: 'rent_info.expiry_date', model: 'asset', format: { type: 'datetime', source: '', isMultiple: false } },
        // { name: '租期', field: 'rent_info.lease_term', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
      ] //
      break
    case 'diagnosisState':
      objTarget = [
        { name: '正常', code: 'normal' },
        { name: '异常', code: 'exception' }
      ]
      break
    case 'diagnosisClass':
      objTarget = [
        { name: '电脑', code: 'CPU', child: ['音频', '电池状况', '显示屏', '风扇', '显卡', '内存', '主板', '电源接口', '传感器', '存储'] },
        { name: '电话', code: 'phone', child: ['电池状况', '蓝牙', '摄像头', '传感器', 'Touch ID', 'Face ID', 'WIFI', '服务', '软件', '无线充电', 'Apple Pay'] }
      ]
      break
    case 'diagnosisRepair':
      objTarget = [
        { name: '整机', code: 'overall' },
        { name: '部件', code: 'unit' }
      ]
      break
    case 'rentType':
      objTarget = [
        { name: '融资租赁', code: '0' },
        { name: '经营性租赁', code: '1' }
      ]
      break
    case 'leaseTerm':
      objTarget = [
        { name: '1年', code: 365 },
        { name: '2年', code: 730 },
        { name: '3年', code: 1095 },
        { name: '4年', code: 1460 }
      ]
      break
    case 'paymentCycle':
      objTarget = [
        { name: '月付', code: '0' },
        { name: '季付', code: '1' }
      ]
      break
    case 'payment_cycle':
      objTarget = [
        { name: '月付', code: '0' },
        { name: '季付', code: '1' },
        { name: '半年付', code: '2' },
        { name: '年付', code: '3' }
      ]
      break
    case 'repairType':
      objTarget = [
        { name: '保内送修', code: '保内送修' },
        { name: '保外送修', code: '保外送修' },
        { name: '三包服务', code: '三包服务' },
      ]
      break
    case 'repairPolicy':
      objTarget = [
        { name: '7天无理由退机', code: 'returnIn7' },
        { name: '7天质量问题退机', code: 'exchangeIn7' },
        { name: '15天质量问题换机', code: 'exchangeQuality' },
        { name: '其它', code: 'other' },
      ]
      break
    case 'expressState':
      objTarget = [
        { name: '揽收', code: '1' },
        { name: '在途', code: '0' },
        { name: '派件', code: '5' },
        // { name: '签收', code: '3' },
        { name: '退签', code: '4' },
        { name: '拒签', code: '14' }
      ]
      break
    case 'assetOwner':
      objTarget = [
        { name: '经销商服务', code: 'dealer' },
        { name: '经销商租赁', code: 'rental' },
        { name: '企业自有', code: 'enterprise' },
        { name: '个人自有', code: 'personal' }
      ]
      break
    case 'preSoftware':
      objTarget = [
        { name: '微软-8XA-00020', code: 'ms8x20' },
        { name: '微软-8XA-00040', code: 'ms8x40' },
        { name: '微软-8XA-00080', code: 'ms8x80' },
        { name: '微软-FPS-00012', code: 'msfps12' },
        { name: '微软-FPS-00044', code: 'msfps44' },
        { name: '微软-FPS-00052', code: 'msfps52' },
        { name: '微软-Office365-E1许可', code: 'msofficee1' },
        { name: '微软-office彩盒家庭版', code: 'msofficehome' },
        { name: '微软软件-79G-05368', code: 'ms79g05368' },
        { name: '微软-软件-w10英文企业版', code: 'mswin10en' },
      ]
      break
    case 'proService':
      objTarget = [
        { name: '部署服务', code: 'deploy' },
        { name: '上门服务', code: 'delivery' },
        { name: '项目管理服务', code: 'project' },
      ]
      break
    case 'rentalDeadline':
      objTarget = [
        { name: '已过期', code: '0' },
        { name: '7天内', code: '7' },
        { name: '7-30天内', code: '30' },
        { name: '30-60天内', code: '60' },
        { name: '60-180天内', code: '180' },
      ]
      break
    case 'BusinessType':
      objTarget = [
        { name: '订单服务', code: 'order' },
        { name: '设备服务', code: 'service' },
        { name: '设备维修', code: 'maintain' }
      ]
      break
    case 'PartyList':
      objTarget = [
        { name: '倍升互联（北京）科技有限公司', code: 'doublerise'},
        { name: '北京麦匙科技有限公司', code: 'mdaas'},
        { name: '江苏创森智云科技有限公司', code: 'jschuangsen'},
      ]
      break
    case 'ProductModule':
      objTarget = [
        { name: '设备服务', code: 'default'},
        { name: '维修服务', code: 'repair'},
        { name: '订单服务', code: 'order'},
        { name: '租赁服务', code: 'rent'},
      ]
      break
    case 'ProductEnable':
      objTarget = [
        { name: '全部', code: ''},
        { name: '是', code: true},
        { name: '否', code: false},
      ]
      break
    case 'cityList':
      objTarget = [
        '国内三线',
        '北京市', '上海市', '天津市', '重庆市',
        '石家庄市', '唐山市', '秦皇岛市', '邯郸市', '邢台市', '保定市', '张家口市', '承德市', '沧州市', '廊坊市', '衡水市', '雄安新区',
        '太原市', '大同市', '阳泉市', '长治市', '晋城市', '朔州市', '晋中市', '运城市', '忻州市', '临汾市', '吕梁市',
        '呼和浩特市', '包头市', '乌海市', '赤峰市', '通辽市', '鄂尔多斯市', '呼伦贝尔市', '巴彦淖尔市', '乌兰察布市', '兴安盟', '锡林郭勒盟', '阿拉善盟',
        '沈阳市', '大连市', '鞍山市', '抚顺市', '本溪市', '丹东市', '锦州市', '营口市', '阜新市', '辽阳市', '盘锦市', '铁岭市', '朝阳市', '葫芦岛市',
        '长春市', '吉林市', '四平市', '辽源市', '通化市', '白山市', '松原市', '白城市', '延边朝鲜族自治州',
        '哈尔滨市', '齐齐哈尔市', '鸡西市', '鹤岗市', '双鸭山市', '大庆市', '伊春市', '佳木斯市', '七台河市', '牡丹江市', '黑河市', '绥化市', '大兴安岭地区',
        '南京市', '无锡市', '徐州市', '常州市', '苏州市', '南通市', '连云港市', '淮安市', '盐城市', '扬州市', '镇江市', '泰州市', '宿迁市',
        '杭州市', '宁波市', '温州市', '嘉兴市', '湖州市', '绍兴市', '金华市', '衢州市', '舟山市', '台州市', '丽水市',
        '合肥市', '芜湖市', '蚌埠市', '淮南市', '马鞍山市', '淮北市', '铜陵市', '安庆市', '黄山市', '滁州市', '阜阳市', '宿州市', '六安市', '亳州市', '池州市', '宣城市',
        '福州市', '厦门市', '莆田市', '三明市', '泉州市', '漳州市', '南平市', '龙岩市', '宁德市',
        '南昌市', '景德镇市', '萍乡市', '九江市', '新余市', '鹰潭市', '赣州市', '吉安市', '宜春市', '抚州市', '上饶市',
        '济南市', '青岛市', '淄博市', '枣庄市', '东营市', '烟台市', '潍坊市', '济宁市', '泰安市', '威海市', '日照市', '临沂市', '德州市', '聊城市', '滨州市', '菏泽市',
        '郑州市', '开封市', '洛阳市', '平顶山市', '安阳市', '鹤壁市', '新乡市', '焦作市', '濮阳市', '许昌市', '漯河市', '三门峡市', '南阳市', '商丘市', '信阳市', '周口市', '驻马店市', '济源市',
        '武汉市', '黄石市', '十堰市', '宜昌市', '襄阳市', '鄂州市', '荆门市', '孝感市', '荆州市', '黄冈市', '咸宁市', '随州市', '恩施土家族苗族自治州', '仙桃市', '潜江市', '天门市', '神农架林区',
        '长沙市', '株洲市', '湘潭市', '衡阳市', '邵阳市', '岳阳市', '常德市', '张家界市', '益阳市', '郴州市', '永州市', '怀化市', '娄底市', '湘西土家族苗族自治州',
        '广州市', '韶关市', '深圳市', '珠海市', '汕头市', '佛山市', '江门市', '湛江市', '茂名市', '肇庆市', '惠州市', '梅州市', '汕尾市', '河源市', '阳江市', '清远市', '东莞市', '中山市', '潮州市', '揭阳市', '云浮市',
        '南宁市', '柳州市', '桂林市', '梧州市', '北海市', '防城港市', '钦州市', '贵港市', '玉林市', '百色市', '贺州市', '河池市', '来宾市', '崇左市',
        '海口市', '三亚市', '三沙市', '儋州市', '五指山市', '琼海市', '文昌市', '万宁市', '东方市',
        '成都市', '自贡市', '攀枝花市', '泸州市', '德阳市', '绵阳市', '广元市', '遂宁市', '内江市', '乐山市', '南充市', '眉山市', '宜宾市', '广安市', '达州市', '雅安市', '巴中市', '资阳市', '阿坝藏族羌族自治州', '甘孜藏族自治州', '凉山彝族自治州',
        '贵阳市', '六盘水市', '遵义市', '安顺市', '毕节市', '铜仁市', '黔西南布依族苗族自治州', '黔东南苗族侗族自治州', '黔南布依族苗族自治州',
        '昆明市', '曲靖市', '玉溪市', '保山市', '昭通市', '丽江市', '普洱市', '临沧市', '楚雄彝族自治州', '红河哈尼族彝族自治州', '文山壮族苗族自治州', '西双版纳傣族自治州', '大理白族自治州', '德宏傣族景颇族自治州', '怒江傈僳族自治州', '迪庆藏族自治州',
        '拉萨市', '日喀则市', '昌都市', '林芝市', '山南市', '那曲市', '阿里地区',
        '西安市', '铜川市', '宝鸡市', '咸阳市', '渭南市', '延安市', '汉中市', '榆林市', '安康市', '商洛市',
        '兰州市', '嘉峪关市', '金昌市', '白银市', '天水市', '武威市', '张掖市', '平凉市', '酒泉市', '庆阳市', '定西市', '陇南市', '临夏回族自治州', '甘南藏族自治州', '西宁市', '海东市', '海北藏族自治州', '黄南藏族自治州', '海南藏族自治州', '果洛藏族自治州', '玉树藏族自治州', '海西蒙古族藏族自治州',
        '银川市', '石嘴山市', '吴忠市', '固原市', '中卫市',
        '乌鲁木齐市', '克拉玛依市', '吐鲁番市', '哈密市', '昌吉回族自治州', '博尔塔拉蒙古自治州', '巴音郭楞蒙古自治州', '阿克苏地区', '克孜勒苏柯尔克孜自治州', '喀什地区', '和田地区', '伊犁哈萨克自治州', '塔城地区', '阿勒泰地区', '石河子市', '阿拉尔市', '图木舒克市', '五家渠市', '北屯市', '铁门关市', '双河市', '可克达拉市', '昆玉市', '胡杨河市', '新星市', '白杨市',
      ]
      break
    case 'RiskBusinessType':
      objTarget = [
        { name: '销售', code: 1 },
        { name: '租赁', code: 2 },
      ]
      break
    case 'ExcepType':
      objTarget = [
        { name: '否决项', code: 0 },
        { name: '经营异常', code: 1 },
        { name: '临期流动性压力', code: 2 },
        { name: '涉案涉诉', code: 3 },
        { name: '负面舆情', code: 4 },
      ]
      break
    case 'PayCondition':
      objTarget = [
        { name: '现款', code: 1 },
        { name: '账期', code: 2 },
      ]
      break
    case 'ApprovalStatus':
      objTarget = [
        { name: '审批中', code: 'PENDING' },
        { name: '通过', code: 'APPROVED' },
        { name: '拒绝', code: 'REJECTED' },
        { name: '撤回', code: 'CANCELED' },
        { name: '删除', code: 'DELETED' },
      ]
      break
    case 'CusType':
      objTarget = [
        { name: '新客户', code: true},
        { name: '旧客户', code: false},
      ]
      break
  }
  return objTarget
};

export default {
  categories,
};
