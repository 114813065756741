import router from '../router'
import store from '../store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { CHECK_AUTH, CREATE_ROUTER, LOGOUT } from '../store/actions.type'
import {SET_ERROR} from "../store/mutations.type";

NProgress.configure({ showSpinner: false });
const whiteList = ['/login', '/register']; // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  NProgress.start()

  const hasToken = store.getters.isAuthenticated;
  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({path: '/'})
      NProgress.done()
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasAccount = store.getters.currentUser;
      if (Object.keys(hasAccount).length > 0) {
        next()
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['dealer','enterprise']
          const accountInfo = await store.dispatch(CHECK_AUTH)
          // generate accessible routes map based on roles
          const accessRoutes = await store.dispatch(CREATE_ROUTER, accountInfo)

          // dynamically add accessible routes
          accessRoutes.forEach(routeConfig => { router.addRoute(routeConfig) })
          // router.addRoutes(accessRoutes)
          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          if (store.getters.currentEmployer.ownerType === 'Enterprise') {
            next('/403') // 目前仅支持运营和经销商登陆
          } else {
            next({ ...to, replace: true })
          }
        }catch (e) {
          // remove token and go to login page to re-login
          await store.dispatch(LOGOUT)
          store.commit(SET_ERROR, {msg: '登陆错误：'+ e.message, color: 'error'})
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done(); // finish progress bar
});


