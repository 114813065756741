// AUTH
export const CHECK_AUTH = 'checkAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const CREATE_ROUTER = 'getRouter';
export const OAUTH_LOGIN = 'feishuLogin';
//ACCOUNT
export const CREATE_ACCOUNT = 'register';
export const FETCH_ACCOUNT_LIST = 'getAccountList';
export const CHECK_USERNAME = 'checkUserName';
export const CHECK_USERPHONE = 'checkUserPhone';
export const EDIT_ACCOUNT = 'updateMineAccount';
// Dealer
export const FETCH_DEALER_LIST = 'getDealerList';
export const FETCH_DEALER_ENTERPRISE = 'getDealerEnterpriseList'
export const CHECK_DEALER_CODE = 'checkDealerInviteCode';
export const AUTH_DEALER = 'authDealer';
export const BLOCK_DEALER = 'blockDealer';
export const BOOKMARK_DEALER = 'setDefaultDealer';
export const DELETE_DEALER = 'deleteDealer';
export const FETCH_DEALER = 'getDealer';
export const PUBLISH_DEALER = 'createDealer';
export const EDIT_DEALER = 'updateDealer';
// Branch
export const FETCH_BRANCH_LIST = 'getBranchList';
export const ORDER_BRANCH_LIST = 'orderBranchList';
export const PUBLISH_BRANCH = 'createBranch';
export const FETCH_BRANCH = 'getBranch';
export const EDIT_BRANCH = 'updateBranch';
export const BLOCK_BRANCH = 'blockBranch';
export const DELETE_BRANCH = 'deleteBranch';
// Catalog
export const FETCH_CATALOG_LIST = 'getCatalogList';
export const ORDER_CATALOG_LIST = 'orderCatalogList';
export const ORDER_CATALOG_CHILDREN = 'orderCatalogChildren';
export const PUBLISH_CATALOG = 'createCatalog';
export const FETCH_CATALOG = 'getCatalog';
export const EDIT_CATALOG = 'updateCatalog';
export const BLOCK_CATALOG = 'blockCatalog';
export const DELETE_CATALOG = 'deleteCatalog';
// KnowledgeCatalog
export const FETCH_KNOWLEDGE_CATALOG_LIST = 'getKnowledgeCatalogList';
export const ORDER_KNOWLEDGE_CATALOG_LIST = 'orderKnowledgeCatalogList';
export const ORDER_KNOWLEDGE_CATALOG_CHILDREN = 'orderKnowledgeCatalogChildren';
export const PUBLISH_KNOWLEDGE_CATALOG = 'createKnowledgeCatalog';
export const FETCH_KNOWLEDGE_CATALOG = 'getKnowledgeCatalog';
export const EDIT_KNOWLEDGE_CATALOG = 'updateKnowledgeCatalog';
export const DELETE_KNOWLEDGE_CATALOG = 'deleteKnowledgeCatalog';
// KNOWLEDGE
export const FETCH_KNOWLEDGE_LIST = 'getKnowledgeList';
export const FETCH_KNOWLEDGE = 'getKnowledge';
export const PUBLISH_KNOWLEDGE = 'createKnowledge';
export const EDIT_KNOWLEDGE = 'updateKnowledge';
export const AUTH_KNOWLEDGE = 'authKnowledge';
export const TOP_KNOWLEDGE = 'topKnowledge';
export const DELETE_KNOWLEDGE = 'deleteKnowledge';
export const CREATE_USEFUL_KNOWLEDGE = 'createUsefulKnowledge';
export const CANCEL_USEFUL_KNOWLEDGE = 'cancelUsefulKnowledge';
// Department
export const FETCH_DEPT_LIST = 'getDeptList';
export const CREATE_DEPT = 'createDept';
export const EDIT_DEPT = 'updateDept';
export const REMOVE_DEPT = 'deleteDept';
// Group
export const FETCH_GROUP_LIST = 'getGroupList';
export const CREATE_GROUP = 'createGroup';
export const EDIT_GROUP = 'updateGroup';
export const REMOVE_GROUP = 'deleteGroup';
// Role
export const FETCH_ROLE_LIST = 'getRoleList';
export const CREATE_ROLE = 'createRole';
export const EDIT_ROLE = 'updateRole';
export const REMOVE_ROLE = 'deleteRole';
// Employee
export const FETCH_EMPLOYEE_LIST = 'getEmployeeList';
export const FETCH_ADMIN_LIST = 'getAdminList';
export const CREATE_ADMIN = 'createAdmin';
export const REMOVE_ADMIN = 'deleteAdmin';
export const FETCH_EMPLOYEE = 'getEmployee';
export const CREATE_EMPLOYEE = 'createEmployee';
export const EDIT_EMPLOYEE = 'updateEmployee';
export const BLOCK_EMPLOYEE = 'blockEmployee';
export const REMOVE_EMPLOYEE = 'deleteEmployee';
// Enterprise
export const CHECK_ENTERPRISE_CODE = 'checkEnterpriseInviteCode';
export const FETCH_ENTERPRISE_LIST = 'getEnterpriseList';
export const FETCH_ENTERPRISE = 'getEnterprise';
export const PUBLISH_ENTERPRISE = 'createEnterprise';
export const EDIT_ENTERPRISE = 'updateEnterprise';
export const AUTH_ENTERPRISE = 'authEnterprise';
export const BLOCK_ENTERPRISE = 'blockEnterprise';
export const DELETE_ENTERPRISE = 'deleteEnterprise';
// EntGroup
export const FETCH_ENTGROUP_LIST = 'getEntGroupList';
export const FETCH_ENTGROUP = 'getEntGroup';
export const PUBLISH_ENTGROUP = 'createEntGroup';
export const EDIT_ENTGROUP = 'updateEntGroup';
export const DELETE_ENTGROUP = 'deleteEntGroup';
// Material
export const FETCH_MATERIAL_LIST = 'getMaterialList';
export const FETCH_MATERIAL = 'getMaterial';
export const PUBLISH_MATERIAL = 'createMaterial';
export const EDIT_MATERIAL = 'updateMaterial';
export const AUTH_MATERIAL = 'authMaterial';
export const BLOCK_MATERIAL = 'blockMaterial';
export const DELETE_MATERIAL = 'deleteMaterial';
// Page
export const FETCH_PAGE = 'getPage';
export const EDIT_PAGE = 'updatePage';
export const EDIT_BODY_ELEMENT = 'updateOneElement';
export const DELETE_BODY_ELEMENT = 'deleteOneElement';
// Service
export const FETCH_SERVICE_LIST = 'getServiceList';
export const PUBLISH_SERVICE = 'createService';
export const EDIT_SERVICE = 'updateService';
export const REMOVE_SERVICE = 'deleteService';
// Tools
export const FETCH_UPLOAD = 'getUploadSign';
export const CHECK_ADDR = 'checkAddress';
export const DELETE_FILE = 'delFile';
export const FETCH_URL = 'getUrlSign';
export const SEND_SMS_CODE = 'sendSMSValidCode';
export const CHECK_SMS_CODE = 'checkSMSValidCode';
export const SEND_EMAIL_CODE = 'sendEmailValidCode';
export const PUBLISH_BUG = 'createBug';
export const FETCH_BUG_LIST = 'getBugList';
export const FETCH_BUG = 'getBug';
export const FETCH_EXPRESS = 'getExpressInfo';
// Setting - CustomField
export const FETCH_FIELD_LIST = 'getAllFieldList';
export const FETCH_FIELDS = 'getFieldList';
export const CREATE_FIELD = 'createField';
export const EDIT_FIELD = 'updateField';
export const REMOVE_FIELD = 'deleteField';
// Setting - Warehouse
export const FETCH_WAREHOUSE_LIST = 'getWarehouseList';
export const PUBLISH_WAREHOUSE = 'createWarehouse';
export const FETCH_WAREHOUSE = 'getWarehouse';
export const EDIT_WAREHOUSE = 'updateWarehouse';
export const ACTIV_WAREHOUSE = 'enableWarehouse';
// Setting - Vendor
export const FETCH_VENDOR_LIST = 'getVendorList';
export const PUBLISH_VENDOR = 'createVendor';
export const FETCH_VENDOR = 'getVendor';
export const EDIT_VENDOR = 'updateVendor';
export const ACTIV_VENDOR = 'enableVendor';
// Setting - Product
export const FETCH_PRODUCT_LIST = 'getProductList';
export const PUBLISH_PRODUCT = 'createProduct';
export const FETCH_PRODUCT = 'getProduct';
export const EDIT_PRODUCT = 'updateProduct';
export const CHECK_PRODUCT = 'checkProduct';
export const ACTIV_PRODUCT = 'enableProduct';
export const FETCH_PRODUCTTYPE_LIST = 'getProductTypeList';
export const PUBLISH_PRODUCTTYPE = 'createProductType';
export const EDIT_PRODUCTTYPE = 'updateProductType';
export const ACTIV_PRODUCTTYPE = 'enableProductType';
// Setting - Rent
export const FETCH_RENT_PARAMETER_LIST = 'getRentParameterList';
export const PUBLISH_RENT_PARAMETER = 'createRentParameter';
export const EDIT_RENT_PARAMETER = 'updateRentParameter';
export const DEFAULT_RENT_PARAMETER = 'setDefaultRentParameter';
export const FETCH_RENT_INDUSTRY_LIST = 'getRentIndustryList';
export const FETCH_RENT_INDUSTRY = 'getRentIndustry';
export const PUBLISH_RENT_INDUSTRY = 'createRentIndustry';
export const EDIT_RENT_INDUSTRY = 'updateRentIndustry';
export const ACTIVE_RENT_INDUSTRY = 'enableRentIndustry';
export const FETCH_RENT_TEMPLATE_LIST = 'getRentTemplateList';
export const PUBLISH_RENT_TEMPLATE = 'createRentTemplate';
export const EDIT_RENT_TEMPLATE = 'updateRentTemplate';
export const ACTIVE_RENT_TEMPLATE = 'enableRentTemplate';
export const TOP_RENT_TEMPLATE = 'topRentTemplate';
// Setting - RiskEconomy
export const FETCH_RISK_ECONOMY_LIST = 'getRiskEconomyList';
export const PUBLISH_RISK_ECONOMY = 'createRiskEconomy';
export const FETCH_RISK_ECONOMY = 'getRiskEconomy';
export const EDIT_RISK_ECONOMY = 'updateRiskEconomy';
export const DELETE_RISK_ECONOMY = 'deleteRiskEconomy';
// Setting - RiskEnterpriseType
export const FETCH_RISK_ENTERPRISETYPE_LIST = 'getRiskEnterpriseTypeList';
export const PUBLISH_RISK_ENTERPRISETYPE = 'createRiskEnterpriseType';
export const FETCH_RISK_ENTERPRISETYPE = 'getRiskEnterpriseType';
export const EDIT_RISK_ENTERPRISETYPE = 'updateRiskEnterpriseType';
export const DELETE_RISK_ENTERPRISETYPE = 'deleteRiskEnterpriseType';
// Setting - RiskCityLevel
export const INIT_RISK_CITY_LEVEL = 'initRiskCityLevel'
export const FETCH_RISK_CITY_LEVEL_LIST = 'getRiskCityLevelList';
export const PUBLISH_RISK_CITY_LEVEL = 'createRiskCityLevel';
export const FETCH_RISK_CITY_LEVEL = 'getRiskCityLevel';
export const EDIT_RISK_CITY_LEVEL = 'updateRiskCityLevel';
export const DELETE_RISK_CITY_LEVEL = 'deleteRiskCityLevel';
// Setting - RiskModel
export const FETCH_RISK_MODEL_LIST = 'getRiskModelList';
export const PUBLISH_RISK_MODEL = 'createRiskModel';
export const FETCH_RISK_MODEL = 'getRiskModel';
export const EDIT_RISK_MODEL = 'updateRiskModel';
export const DELETE_RISK_MODEL = 'deleteRiskModel';
// Setting - RiskEnterprise
export const FETCH_RISK_ENTERPRISE_LIST = 'getRiskEnterpriseList';
export const PUBLISH_RISK_ENTERPRISE = 'createRiskEnterprise';
export const FETCH_RISK_ENTERPRISE = 'getRiskEnterprise';
export const EDIT_RISK_ENTERPRISE = 'updateRiskEnterprise';
export const DELETE_RISK_ENTERPRISE = 'deleteRiskEnterprise';
export const CAL_CREDITLINE = 'calCreditLine';
export const RE_CAL_CREDITLINE = 'reCalCreditLine';
export const REFRESH_RISK_ENTERPRISE = 'refreshRiskEnterprise';
export const FETCH_KINGDEE_CUSTOMER_LIST = 'getKingdeeCustomerList';
export const UPDATE_KINGDEE_CREDITLINE = 'updateKingdeeCreditLine';
export const FETCH_RECEIVABLE_OVERDUE = 'getReceivableOverdue';
export const FETCH_RECEIVABLE_OVERDUE_SUMMARY = 'getReceivableOverdueSummary';
export const FETCH_QCC_ACCT_SCAN = 'getQccAcctScan';
// Setting - RiskEntGroup
export const FETCH_RISK_ENTGROUP_LIST = 'getRiskEntGroupList';
export const ADJUST_RISK_ENTGROUP_CREDITLINE = 'adjustRiskEntGroupCreditLine';
// Risk - RiskApproval
export const FETCH_RISK_APPROVAL_LIST = 'getRiskApprovalList';
export const FETCH_RISK_APPROVAL = 'getRiskApproval';
export const PUBLISH_RISK_APPROVAL = 'createRiskApproval';
export const FETCH_QCC_NAME_SEARCH_LIST = 'getQccNameSearchList';
export const APPROVE_RISK_APPROVAL = 'approveRiskApproval';
export const REJECT_RISK_APPROVAL = 'rejectRiskApproval';
export const FETCH_KINGDEE_NAME_SEARCH_LIST = 'getKingdeeNameSearchList';
export const FETCH_KINGDEE_ORG_LIST = 'getKingdeeOrgList';
export const PUBLISH_RISK_PREAPPROVAL = 'preApproval';
export const FETCH_KINGDEE_CUSTOMER = 'getKingdeeCustomer';
// Risk - RiskLog
export const FETCH_RISK_LOG_LIST = 'getRiskLogList';
// Asset
export const FETCH_ASSET_LIST = 'getAssetList';
export const EXPORT_ASSET_LIST = 'exportAssetList';
export const PUBLISH_ASSET = 'createAsset';
export const FETCH_ASSET = 'getAsset';
export const EDIT_ASSET = 'updateAsset';
export const BATCH_ASSET = 'batchUpdateAsset';
export const ACTIV_ASSET = 'enableAsset';
export const FETCH_ASSETSTATE_LIST = 'getAssetStateList';
export const PUBLISH_ASSETSTATE = 'createAssetState';
export const EDIT_ASSETSTATE = 'updateAssetState';
export const ACTIV_ASSETSTATE = 'enableAssetState';
export const LOCK_ASSET = 'lockAsset';
//Workflow
export const FETCH_TASK_LIST = 'getTaskList';
export const PUBLISH_TASK = 'createTask';
export const EDIT_TASK = 'updateTask';
export const ACTIV_TASK = 'enableTask';
export const FETCH_PROCESS_LIST = 'getProcessList';
export const PUBLISH_PROCESS = 'createProcess';
export const EDIT_PROCESS = 'updateProcess';
export const ACTIV_PROCESS = 'enableProcess';
// Case
export const INIT_CASE = 'initCase';
export const PUBLISH_CASE = 'createCase';
export const FETCH_CASE = 'getCase';
export const FETCH_CASE_LIST = 'getCaseList';
export const ASYNC_CASE_LIST = 'asyncCaseList';
export const REJECT_CASE = 'rejectCase';
export const SUBMIT_CASE = 'submitCase';
export const SAVE_CASE = 'saveCase';
export const CANCEL_CASE = 'cancelCase';
export const ASSIST_CASE = 'assistCase';
export const PUBLISH_CASE_REPLY = 'replyConsultCase';
export const RESOLVED_CASE = 'resolvedConsultCase';
export const RELATE_SERIAL = 'relateSerial';
export const REMOVE_CASE_ITEM = 'deleteItemInCase'
// RENT SERVICE
export const INIT_RENT_QUOTE = 'initRentQuote';
export const PUBLISH_RENT_QUOTE = 'createRentQuote';
export const FETCH_RENT_QUOTE = 'getRentQuote';
export const FETCH_RENT_QUOTE_LIST = 'getRentQuoteList';
export const EDIT_RENT_QUOTE = 'editRentQuote';
export const APPROVE_RENT_QUOTE = 'approveRentQuote';
export const DELETE_RENT_QUOTE = 'deleteRentQuote';
export const PUBLISH_RENT_QUOTE_SHEET = 'createRentQuoteSheet';
export const FETCH_RENT_QUOTE_SHEET = 'getRentQuoteSheet';
export const FETCH_RENT_QUOTE_SHEET_LIST = 'getRentQuoteSheetList';
export const DOWNLOAD_RENT_QUOTE_SHEET = 'downloadRentQuoteSheet';
// RENT EARNINGS
export const FETCH_EARNINGS_LIST = 'getEarningsList';
export const PUBLISH_EARNINGS = 'createEarnings';
export const EDIT_EARNINGS = 'updateEarnings';
export const REMOVE_EARNINGS = 'deleteEarnings';
// Setting - Logs
export const FETCH_LOGS_LIST = 'getLogsList';
// Setting - Release
export const FETCH_RELEASE_LIST = 'getReleaseList';
export const PUBLISH_RELEASE = 'createRelease';
export const DELETE_RELEASE = 'deleteRelease';
export const EDIT_RELEASE = 'updateRelease';
export const FETCH_RELEASE = 'getRelease';
//REPORT
export const FETCH_REPORT_ASSET_COUNT = 'getAssetCount';
export const FETCH_REPORT_CASEGROUP_COUNT = 'getCaseGroupCount';
export const FETCH_REPORT_ALL_COUNT = 'getAllCount';
export const FETCH_REPORT_CASE_HISTORY = 'getHistoryCase';
export const FETCH_REPORT_ASSET_HISTORY = 'getHistoryAsset';
export const FETCH_REPORT_ENTERPRISE_HISTORY = 'getHistoryEnterprise';
export const FETCH_REPORT_ACCOUNT_HISTORY = 'getHistoryAccount';
export const FETCH_REPORT_ORDER_HISTORY = 'getHistoryOrder';
export const FETCH_REPORT_CASE_COUNT_YEAR = 'getCaseCountYear';
export const FETCH_REPORT_CASE_STATUS = 'getCaseStatus';
export const FETCH_REPORT_RENT_OFFER_STATUS = 'getRentOfferStatus';
export const FETCH_REPORT_ORDER_PURCHASE_STATUS = 'getOrderPurchaseStatus';
export const FETCH_REPORT_PURCHASE_STATUS = 'getPurchaseStatus';
export const FETCH_REPORT_RECEIVABLE_COUNT = 'getReceivableCount';
export const FETCH_REPORT_RISK_COUNT = 'getRiskCount';
//MONTHLY
export const FETCH_MONTHLY_REPORT_LIST = 'getMonthlyReportList'
export const EDIT_MONTHLY_REPORT = 'updateMonthlyReport';
export const FETCH_MONTHLY_REPORT = 'getMonthlyReport';
export const PUBLISH_MONTHLY_REPORT = 'publishMonthlyReport';
//ORDER
export const INIT_ORDER_PURCHASE = 'initPurchaseOrder';
export const PUBLISH_ORDER_PURCHASE = 'createPurchaseOrder';
export const FETCH_ORDER_PURCHASE_LIST = 'getPurchaseOrderList';
export const FETCH_ORDER_PURCHASE = 'getPurchaseOrder';
export const EDIT_ORDER_PURCHASE = 'editPurchaseOrder';
export const CANCEL_ORDER_PURCHASE = 'cancelPurchaseOrder';
export const ADD_MARK_ORDER_PURCHASE = 'setMarkPurchaseOrder'
export const REMOVE_MARK_ORDER_PURCHASE = 'cancelMarkPurchaseOrder'
//PURCHASE
export const PUBLISH_PURCHASE = 'createPurchase';
export const FETCH_PURCHASE = 'getPurchase';
export const FETCH_PURCHASE_LIST = 'getPurchaseList';
export const ASYNC_PURCHASE_LIST = 'asyncPurchaseList';
export const SAVE_PURCHASE = 'savePurchase';
export const CANCEL_PURCHASE = 'cancelPurchase';
export const ASSIST_PURCHASE = 'assistPurchase';
export const SUBMIT_PURCHASE = 'submitPurchase';
export const ADD_MARK_PURCHASE = 'setMarkPurchase'
export const REMOVE_MARK_PURCHASE = 'cancelMarkPurchase'
//NOTES
export const PUBLISH_NOTES = 'createNotes';
export const FETCH_NOTES_LIST = 'getNotesList';
export const FETCH_NOTES = 'getNotes';
export const EDIT_NOTES = 'editNotes';
export const REMOVE_NOTES = 'deleteNotes';
//THEME
export const CHANGE_THEME = 'changeTheme';




