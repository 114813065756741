var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', {
    attrs: {
      "value": _vm.activeCaseTab
    }
  }, [_c('v-tab', [_vm._v("外采")]), _c('v-tab', [_vm._v("服务")]), _c('v-tab-item', [_c('v-card-text', {
    staticClass: "px-0"
  }, [_c('v-data-table', {
    staticClass: "elevation-0 my-4",
    attrs: {
      "headers": _vm.purchaseHeaders,
      "items": _vm.purchaseList,
      "items-per-page": 50,
      "hide-default-footer": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "item.request_number",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('td', {
          staticClass: "v-list-item--link",
          attrs: {
            "nowrap": "true"
          },
          on: {
            "click": function click($event) {
              return _vm.goToPurchase(item.request_number);
            }
          }
        }, [_vm._v(_vm._s(item.request_number))])];
      }
    }, {
      key: "item.case_info.case_number",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.case_info && item.case_info.case_number ? _c('td', {
          staticClass: "v-list-item--link",
          attrs: {
            "nowrap": "true"
          },
          on: {
            "click": function click($event) {
              return _vm.goToCase(item.case_info.case_number);
            }
          }
        }, [_vm._v(_vm._s(item.case_info.case_number))]) : _vm._e()];
      }
    }, {
      key: "item.product_name_list",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, _vm._l(item.product_name_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            staticStyle: {
              "width": "200px"
            },
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-truncate",
            staticStyle: {
              "max-width": "200px"
            }
          }, [_vm._v(_vm._s(pn))])])], 1);
        }), 0)];
      }
    }, {
      key: "item.product_quantity_list",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return _vm._l(item.product_quantity_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-center",
            staticStyle: {
              "min-width": "40px"
            }
          }, [_vm._v(_vm._s(pn))])])], 1);
        });
      }
    }, {
      key: "item.product_purchase_price_list",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return _vm._l(item.product_purchase_price_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-right",
            staticStyle: {
              "min-width": "75px"
            }
          }, [_vm._v(_vm._s(_vm._f("formatMoney")(pn.$numberDecimal)))])])], 1);
        });
      }
    }, {
      key: "item.product_purchase_margin_list",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return _vm._l(item.product_purchase_margin_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-right",
            staticStyle: {
              "min-width": "75px"
            }
          }, [_vm._v(_vm._s(parseFloat(pn * 100).toFixed(2)) + "%")])])], 1);
        });
      }
    }, {
      key: "item.editor",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.editor && item.editor.personal ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.editor.personal.name))]) : _vm._e()];
      }
    }, {
      key: "item.updateTime",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [item.status === 0 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("待受理")]) : item.status === 1 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "primary"
          }
        }, [_vm._v("处理中")]) : item.status === 2 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.alertExplain(item.explain);
            }
          }
        }, [_vm._v("已取消 "), _c('v-icon', {
          attrs: {
            "right": "",
            "small": ""
          }
        }, [_vm._v("sms")])], 1) : item.status === 3 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已取消")]) : _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已完成")])], 1)];
      }
    }])
  })], 1)], 1), _c('v-tab-item', [_c('v-card-text', {
    staticClass: "px-0"
  }, [_c('v-data-table', {
    staticClass: "elevation-0 my-4",
    attrs: {
      "headers": _vm.caseHeaders,
      "items": _vm.orderData.case_list,
      "items-per-page": 50,
      "hide-default-footer": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "item.case_number",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('span', {
          staticClass: "v-list-item--link",
          on: {
            "click": function click($event) {
              return _vm.goToCase(item.case_number);
            }
          }
        }, [_vm._v(_vm._s(item.case_number))])];
      }
    }, {
      key: "item.current_task_id",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('span', [_vm._v(_vm._s(item.status === 0 ? "订单处理" : item.current_task_id ? item.current_task_id.name : "无" || "无"))])];
      }
    }, {
      key: "item.ordered_quantity",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(item.product_count ? item.product_count : "0") + " ")];
      }
    }, {
      key: "item.link_asset",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_vm._v(" " + _vm._s(item.link_asset.length) + " ")];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [item.status === 0 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("待受理")]) : item.status === 1 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "primary"
          }
        }, [_vm._v("处理中")]) : item.status === 2 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已取消")]) : item.status === 3 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已取消")]) : _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已完成")])], 1)];
      }
    }, {
      key: "item.order_assigner",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [item.order_assigner && item.order_assigner.personal ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.order_assigner.personal.name))]) : _vm._e()];
      }
    }, {
      key: "item.updateTime",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_vm._v(" " + _vm._s(_vm._f("formatTime")(item.updateTime)) + " ")];
      }
    }, {
      key: "item.createTime",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_vm._v(" " + _vm._s(_vm._f("formatTime")(item.createTime)) + " ")];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref19) {
              var on = _ref19.on,
                  attrs = _ref19.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.exportAsset(item);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("save_alt")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("导出设备")])])];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.orderData.no_order_text ? _vm.orderData.no_order_text : "无工单数据") + " "), _vm.isEdit ? _c('v-btn', {
          attrs: {
            "icon": "",
            "small": ""
          },
          on: {
            "click": _vm.readyNoData
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("edit")])], 1) : _vm._e()];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }