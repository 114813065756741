var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-bottom-navigation', {
    attrs: {
      "color": "secondary",
      "grow": "",
      "app": ""
    }
  }, [this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('System_RiskStaff') || this.currentUser.permission.includes('System_RiskAdmin') ? _c('v-btn', {
    on: {
      "click": _vm.goTo2
    }
  }, [_vm._v(" 实地尽调 "), _c('v-icon', [_vm._v("travel_explore")])], 1) : _vm._e(), this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('System_RiskStaff') || this.currentUser.permission.includes('System_RiskAdmin') ? _c('v-btn', {
    on: {
      "click": _vm.goTo4
    }
  }, [_c('span', [_vm._v("查询授信")]), _c('v-icon', [_vm._v("manage_search")])], 1) : _vm._e(), this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('System_RiskStaff') || this.currentUser.permission.includes('System_RiskAdmin') ? _c('v-btn', {
    on: {
      "click": _vm.goTo3
    }
  }, [_c('span', [_vm._v("预授信")]), _c('v-icon', [_vm._v("credit_card")])], 1) : _vm._e(), this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('System_RiskStaff') || this.currentUser.permission.includes('System_RiskAdmin') ? _c('v-btn', {
    on: {
      "click": _vm.goTo1
    }
  }, [_c('span', [_vm._v("授信申请")]), _c('v-icon', [_vm._v("currency_exchange")])], 1) : _vm._e(), this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('System_RiskAdmin') ? _c('v-btn', {
    on: {
      "click": _vm.goTo
    }
  }, [_vm._v(" 授信审批 "), _c('v-icon', [_vm._v("approval")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }