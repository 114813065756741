<template>
  <v-bottom-navigation color="secondary" grow app>
    <v-btn @click="goTo2" v-if="this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('System_RiskStaff') || this.currentUser.permission.includes('System_RiskAdmin')">
      实地尽调
      <v-icon>travel_explore</v-icon>
    </v-btn>
    <v-btn @click="goTo4" v-if="this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('System_RiskStaff') || this.currentUser.permission.includes('System_RiskAdmin')">
      <span>查询授信</span>
      <v-icon>manage_search</v-icon>
    </v-btn>
    <v-btn @click="goTo3" v-if="this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('System_RiskStaff') || this.currentUser.permission.includes('System_RiskAdmin')">
      <span>预授信</span>
      <v-icon>credit_card</v-icon>
    </v-btn>
    <v-btn @click="goTo1" v-if="this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('System_RiskStaff') || this.currentUser.permission.includes('System_RiskAdmin')">
      <span>授信申请</span>
      <v-icon>currency_exchange</v-icon>
    </v-btn>
    <v-btn @click="goTo" v-if="this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('System_RiskAdmin')">
      授信审批
      <v-icon>approval</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data: () => ({
  }),
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    goTo() {
      this.$router.push({ path: '/dealer/creditline/review' });
    },
    goTo1() {
      this.$router.push({ path: '/dealer/creditline/apply' });
    },
    goTo2() {
      location.href = 'https://doublerise.feishu.cn/share/base/form/shrcn8HCtFeyrSlf9Iso56qPi1b';
    },
    goTo3() {
      this.$router.push({ path: '/dealer/creditline/preapply' });
    },
    goTo4() {
      this.$router.push({ path: '/dealer/creditline/query' });
    },
  },
}
</script>
