<template>
  <v-navigation-drawer v-model="drawer" clipped app :mini-variant="mini">
    <v-list dense nav v-if="!mini">
      <template v-for="item in drawer_routers">
        <v-divider v-if="item.meta.divider" :key="item.name"></v-divider>
        <v-subheader v-if="item.meta.divider" :key="item.meta.title">{{item.meta.title}}</v-subheader>
        <v-list-item v-else :to="item.path" link color="primary" :key="item.name">
          <v-list-item-icon><v-icon>{{ item.meta.icon }}</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.meta.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <core-request-bug>提交 BUG</core-request-bug>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    drawer: null,
    mini: false,
  }),
  created () {
    window.getApp.$on('APP_DRAWER_TOGGLED', () => {
      this.drawer = (!this.drawer);
    });
    window.getApp.$on('APP_DRAWER_HIDE', () => {
      if (this.drawer) {
        this.drawer = false;
      }
    });
    window.getApp.$on('APP_DRAWER_MINI', () => {
      this.mini = true;
    })
  },
  computed: {
    ...mapGetters(['currentRoutes', 'currentUser', 'currentEmployer']),
    identity: function() {
      let strRole = '';
      if (this.currentUser.hasAdmin.includes('admin')) {
        strRole = 'admin'
      } else if (this.currentEmployer.ownerType === 'Dealer') {
        strRole = 'dealer'
      } else {
        strRole = 'enterprise'
      }
      return strRole;
    },
    drawer_routers: function() {
      let colsArray = [];
      this.currentRoutes.forEach((item) => {
        if (!item.hidden && item.meta.roles.includes(this.identity)) {
          colsArray.push(item);
        }
      });
      return colsArray;
    }
  }
}
</script>
