<template>
  <v-app>
    <core-drawer v-if="isAuthenticated && !$route.meta.public && !$route.meta.isMobile" class="d-print-none" />
    <core-toolbar v-if="isAuthenticated && !$route.meta.public && !$route.meta.isMobile" class="d-print-none" />
    <core-mobile-toolbar v-if="isAuthenticated && !$route.meta.public && $route.meta.isMobile" class="d-print-none" :title="$route.meta.title" />
    <core-view />
    <core-footer v-if="isAuthenticated && !$route.meta.public && !$route.meta.isMobile" class="d-print-none" />
    <core-mobile-footer v-if="isAuthenticated && !$route.meta.public && $route.meta.isMobile" class="d-print-none" />
    <widgets-error />
    <widgets-popup-tips></widgets-popup-tips>
    <widgets-update-message></widgets-update-message>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import SocketService from '@/common/socket';
import JwtService from "@/common/jwt";

export default {
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  created () {
    const inQuery = this.$route.query
    if (inQuery && inQuery.token) JwtService.saveToken(inQuery.token);

    window.getApp = this;
    if (JwtService.getToken()) {
      SocketService.auth = { sessionID: JwtService.getToken() }
      SocketService.connect()
    }
    SocketService.on("connect_error", (err) => {
      console.log('socket error =>'+ err)
    });
  },
  destroyed() {
    SocketService.off("connect_error");
  },
}

</script>
<style lang="scss">
  #nprogress .bar {
    background: #d41746 !important;
  }
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }
</style>

