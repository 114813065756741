// AUTH
export const SET_AUTH = "setAuth";
export const SET_USER = "setUser";
export const PURGE_AUTH = "logOut";
export const SET_ERROR = "setError";
export const SET_ROUTERS = 'setRouters';
// ACCOUNT
export const GET_ACCOUNTS = 'startAccountList';
export const SET_ACCOUNTS = 'setAccountList';
// ASSET
export const LOAD_ASSETSTATE_LIST = 'startAssetStateList';
export const SET_ASSETSTATE_LIST = 'setAssetStateList';
export const LOAD_ASSET_LIST = 'startAssetList';
export const SET_ASSET_LIST = 'setAssetList';
export const SET_ASSET = 'setAsset';
// ENTERPRISE
export const LOAD_ENTERPRISES = 'startEnterpriseList';
export const SET_ENTERPRISE_LIST = 'setEnterpriseList';
export const SET_ENTERPRISE = 'setEnterprise';
// ENTGROUP
export const LOAD_ENTGROUP_LIST = 'startEntGroupList';
export const SET_ENTGROUP_LIST = 'setEntGroupList';
export const SET_ENTGROUP = 'setEntGroup';
// MATERIAL
export const LOAD_MATERIALS = 'startMaterialList';
export const SET_MATERIAL_LIST = 'setMaterialList';
export const SET_MATERIAL = 'setMaterial';
// Page
export const SET_PAGE = 'setPAGE';
// SERVICE
export const SET_SERVICE_LIST = 'setServiceList';
// DEALER
export const GET_DEALERS = 'startDealerList';
export const SET_DEALERS = 'setDealers';
export const SET_DEALER_ENTERPRISE = 'setDealerEnterprise';
export const SET_DEALER = 'setDealer';
// Branch
export const LOAD_BRANCH_LIST = 'startBranchList';
export const SET_BRANCH_LIST = 'setBranchList';
export const SET_BRANCH = 'setBranch';
// Catalog
export const LOAD_CATALOG_LIST = 'startCatalogList';
export const SET_CATALOG_LIST = 'setCatalogList';
export const SET_CATALOG = 'setCatalog';
// KnowledgeCatalog
export const LOAD_KNOWLEDGE_CATALOG_LIST = 'startKnowledgeCatalogList';
export const SET_KNOWLEDGE_CATALOG_LIST = 'setKnowledgeCatalogList';
export const SET_KNOWLEDGE_CATALOG = 'setKnowledgeCatalog';
// KNOWLEDGE
export const LOAD_KNOWLEDGE = 'startKnowledgeList';
export const SET_KNOWLEDGE_LIST = 'setKnowledgeList';
export const SET_KNOWLEDGE = 'setKnowledge';
// Department
export const GET_DEPTS = 'startDeptList';
export const SET_DEPTS = 'setDeptList';
export const SET_DEPT = 'setDeptInfo';
// Group
export const LOAD_GROUP_LIST = 'startGroupList';
export const SET_GROUP_LIST = 'setGroupList';
export const SET_GROUP = 'setGroupInfo';
// Role
export const LOAD_ROLE_LIST = 'startRoleList';
export const SET_ROLE_LIST = 'setRoleList';
export const SET_ROLE = 'setRoleInfo';
// EMPLOYEE
export const SET_EMPLOYEE_LIST = 'setEmployeeList';
export const SET_ADMIN_LIST = 'setAdminList';
export const LOAD_EMPLOYEE = 'startEmployee';
export const SET_EMPLOYEE = 'setEmployee';
// TOOLS
export const SET_SIGN = 'setUploadSign';
export const SET_URL = 'setUrlSign';
export const LOAD_BUG = 'startBugList';
export const SET_BUG_LIST = 'setBugList';
export const SET_BUG = 'setBug';
// SETTING
export const LOAD_FIELD_LIST = 'startAllFieldList';
export const SET_FIELD_LIST = 'setAllFieldList';
export const SET_FIELD = 'setField';
export const PURGE_FIELD = 'clearField';
export const LOAD_WAREHOUSE_LIST = 'startWarehouseList';
export const SET_WAREHOUSE_LIST = 'setWarehouseList';
export const LOAD_VENDOR_LIST = 'startVendorList';
export const SET_VENDOR_LIST = 'setVendorList';
export const LOAD_PRODUCT_LIST = 'startProductList';
export const SET_PRODUCT_LIST = 'setProductList';
export const SET_PRODUCT = 'setProduct';
export const LOAD_PRODUCTTYPE_LIST = 'startProductTypeList';
export const SET_PRODUCTTYPE_LIST = 'setProductTypeList';
export const LOAD_RENT_PARAMETER_LIST = 'startRentParameterList';
export const SET_RENT_PARAMETER_LIST = 'setRentParameterList';
export const LOAD_RENT_INDUSTRY_LIST = 'startRentIndustryList';
export const SET_RENT_INDUSTRY_LIST = 'setRentIndustryList';
export const SET_INDUSTRY = 'setIndustry';
export const LOAD_RENT_TEMPLATE_LIST = 'startRentTemplateList';
export const SET_RENT_TEMPLATE_LIST = 'setRentTemplateList';
export const LOAD_RELEASE_LIST = 'startReleaseList';
export const SET_RELEASE_LIST = 'setReleaseList';
export const LOAD_RISK_ECONOMY_LIST = 'startRiskEconomyList';
export const SET_RISK_ECONOMY_LIST = 'setRiskEconomyList';
export const SET_RISK_ECONOMY = 'setRiskEconomy';
export const LOAD_RISK_ENTERPRISETYPE_LIST = 'startRiskEnterpriseTypeList';
export const SET_RISK_ENTERPRISETYPE_LIST = 'setRiskEnterpriseTypeList';
export const SET_RISK_ENTERPRISETYPE = 'setRiskEnterpriseType';
export const LOAD_RISK_CITY_LEVEL_LIST = 'startRiskCityLevelList';
export const SET_RISK_CITY_LEVEL_LIST = 'setRiskCityLevelList';
export const SET_RISK_CITY_LEVEL = 'setRiskCityLevel';
export const LOAD_RISK_MODEL_LIST = 'startRiskModelList';
export const SET_RISK_MODEL_LIST = 'setRiskModelList';
export const SET_RISK_MODEL = 'setRiskModel';
export const LOAD_RISK_ENTERPRISE_LIST = 'startRiskEnterpriseList';
export const SET_RISK_ENTERPRISE_LIST = 'setRiskEnterpriseList';
export const SET_RISK_ENTERPRISE = 'setRiskEnterprise';
export const LOAD_RISK_ENTGROUP_LIST = 'startRiskEntGroupList';
export const SET_RISK_ENTGROUP_LIST = 'setRiskEntGroupList';
export const LOAD_RISK_APPROVAL_LIST = 'startRiskApprovalList';
export const SET_RISK_APPROVAL_LIST = 'setRiskApprovalList';

export const SET_RISK_APPROVAL = 'setRiskApproval';
export const LOAD_QCC_NAME_SEARCH_LIST = 'startQccNameSearchList';
export const SET_QCC_NAME_SEARCH_LIST = 'setQccNameSearchList';
// RISK_LOG
export const LOAD_RISK_LOG_LIST = 'startRiskLogList';
export const SET_RISK_LOG_LIST = 'setRiskLogList';
// WORKFLOW
export const LOAD_TASK_LIST = 'startTaskList';
export const SET_TASK_LIST = 'setTaskList';
export const LOAD_PROCESS_LIST = 'startProcessList';
export const SET_PROCESS_LIST = 'setProcessList';
// CASE
export const SET_CASE_INIT = 'setInitCase';
export const LOAD_CASE_LIST = 'startCaseList';
export const SET_CASE_LIST = 'setCaseList';
export const SET_CASE = 'setCase';
// RENT
export const SET_RENT_QUOTE_INIT = 'setInitRentQuote';
export const LOAD_RENT_QUOTE_LIST = 'startRentQuoteList';
export const SET_RENT_QUOTE_LIST = 'setRentQuoteList';
export const SET_RENT_QUOTE = 'setRentQuote';
export const LOAD_RENT_QUOTE_SHEET_LIST = 'startRentQuoteSheetList';
export const SET_RENT_QUOTE_SHEET_LIST = 'setRentQuoteSheetList';
export const SET_RENT_QUOTE_SHEET = 'setRentQuoteSheet';
// EARNINGS
export const SET_EARNINGS_LIST = 'setEarningsList';
// LOGS
export const SET_LOGS = 'addLog';
export const LOAD_LOGS = 'startLogsList';
export const SET_LOGS_LIST = 'setLogsList';
// MONTHLY
export const SET_MONTHLY_REPORT_LIST = 'setMonthlyReportList';
export const LOAD_MONTHLY_REPORT = 'startMonthlyReport';
export const SET_MONTHLY_REPORT = 'setMonthlyReport';
// ORDER
export const SET_ORDER_PURCHASE_INIT = 'setInitPurchaseOrder';
export const LOAD_ORDER_PURCHASE_LIST = 'startPurchaseOrderList';
export const SET_ORDER_PURCHASE_LIST = 'setPurchaseOrderList';
export const SET_ORDER_PURCHASE = 'setPurchaseOrder';
// PURCHASE
export const LOAD_PURCHASE_LIST = 'startPurchaseList';
export const SET_PURCHASE_LIST = 'setPurchaseList';
export const SET_PURCHASE = 'setPurchase';
// NOTES
export const LOAD_NOTES_LIST = 'startNotesList';
export const SET_NOTES_LIST = 'setNotesList';
export const SET_NOTES = 'setNotes';
// THEME
export const SET_THEME = 'setTheme';
