<template>
  <div id="login_container"></div>
</template>
<script>

export default {
  props: {
    appId: {
      type: String,
      required: true
    },
    redirectUri: {
      type: String,
      required: true
    },
  },
  data() {
    return {

    };
  },
  created() {
    this.$nextTick(() => {
      this.initQrCode();
    });
  },
  methods: {
    initQrCode() {
      const goto = `https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=${this.appId}&redirect_uri=${encodeURIComponent(this.redirectUri)}&response_type=code&state=STATE`;
      try {
        let QRLoginObj = window.QRLogin({
          id: 'login_container',
          goto,
          width: '500',
          height: '500',
          style: 'width:500px;height:300px;border:0;',
        });

        let handleMessage = function (event) {
          // 使用 matchOrigin 和 matchData 方法来判断 message 和来自的页面 url 是否合法
          if (QRLoginObj.matchOrigin(event.origin) && QRLoginObj.matchData(event.data)) {
            var loginTmpCode = event.data.tmp_code;
            // 在授权页面地址上拼接上参数 tmp_code，并跳转
            window.location.href = `${goto}&tmp_code=${loginTmpCode}`;
          }
        };

        if (typeof window.addEventListener != 'undefined') {

          window.addEventListener('message', handleMessage, false);
        }
        else if (typeof window.attachEvent != 'undefined') {

          window.attachEvent('onmessage', handleMessage);
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
