export default [
  {
    path: '/dealer/workbench/delivery/list',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/workbench/delivery/list`
    ),
    name: 'Delivery',
    meta: {
      title: '交付管理',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/workbench/delivery/edit',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/workbench/delivery/create`
    ),
    name: 'Delivery_Create',
    meta: {
      title: '创建交付',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/workbench/order/list',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/workbench/order/list`
    ),
    name: 'Order',
    meta: {
      title: '订单管理',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/workbench/purchase/list',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/workbench/purchase/list`
    ),
    name: 'Purchase',
    meta: {
      title: '外采请求',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/workbench/order/edit',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/workbench/order/create`
    ),
    name: 'Order_Create',
    meta: {
      title: '创建订单',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/workbench/rent/list',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/rent/list`
    ),
    name: 'Lease',
    meta: {
      title: '租赁报价',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/workbench/rent/create',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/rent/create`
    ),
    name: 'Lease_Sale',
    meta: {
      title: '新建报价',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/workbench/rent/offer',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/rent/offer`
    ),
    name: 'Lease_Line',
    meta: {
      title: '报价详情',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/workbench/service/list',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/workbench/service/list`
    ),
    name: 'Service',
    meta: {
      title: '设备管理服务',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/workbench/service/create',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/workbench/service/create`
    ),
    name: 'Service_Edit',
    meta: {
      title: '创建设备管理服务',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/workbench/maintain/list',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/workbench/maintain/list`
    ),
    name: 'Maintain',
    meta: {
      title: '设备维修服务',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/workbench/maintain/create',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/workbench/maintain/create`
    ),
    name: 'Maintain_Edit',
    meta: {
      title: '创建维修诊断服务',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/workbench/advisory/list',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/workbench/advisory/list`
    ),
    name: 'Advisory',
    meta: {
      title: '问题咨询服务',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/workbench/advisory/create',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/workbench/advisory/create`
    ),
    name: 'Advisory_Edit',
    meta: {
      title: '创建问题咨询服务',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/workbench/edit',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/workbench/edit`
    ),
    name: 'Workbench_Edit',
    meta: {
      title: '处理任务',
      roles: ['dealer']
    }
  },
  {
    path: '',
    name: 'Enterprise_Line',
    meta: {
      divider: true,
      title: '企业管理',
      icon: '',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/enterprise',
    redirect: {
      name: 'Enterprise_Enterprise'
    },
    name: 'Enterprise',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/enterprise'
    ),
    meta: {
      title: '企业管理',
      icon: 'location_city',
      roles: ['dealer']
    },
    props: true,
    children: [
      {
        path: 'enterprise',
        name: 'Enterprise_Enterprise',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/enterprise/enterprise'
        ),
        meta: {
          title: '企业管理',
          roles: ['dealer']
        }
      },
      {
        path: 'group',
        name: 'Enterprise_Group',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/enterprise/group'
        ),
        meta: {
          title: '集团管理',
          roles: ['dealer']
        }
      },
    ]
  },
  {
    path: '/dealer/enterprise/edit/:id',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/enterprise_edit`
    ),
    name: 'Enterprise_Edit',
    meta: {
      title: '编辑企业',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/employee',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/employee'
    ),
    name: 'Enterprise_Employee',
    meta: {
      title: '企业员工',
      icon: 'assignment_ind',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/asset',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/asset/index'
    ),
    name: 'Asset',
    meta: {
      title: '设备管理',
      icon: 'widgets',
      roles: ['dealer']
    },
  },
  {
    path: '/dealer/asset/list',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/asset/list`
    ),
    name: 'Asset_Line',
    meta: {
      title: '设备列表',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/asset/edit/:id',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/asset/edit`
    ),
    name: 'Asset_Manage',
    meta: {
      title: '编辑设备',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/report/publish',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/report/publish'
    ),
    name: 'Report_Publish',
    meta: {
      title: '服务报告',
      icon: 'analytics',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/risk/approval',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/risk/approval'
    ),
    name: 'Enterprise_Risk_Approval',
    meta: {
      title: '企业授信',
      icon: 'new_releases',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/risk/approval/edit',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/risk/approval_edit`
    ),
    name: 'Enterprise_Risk_Approval_Edit',
    meta: {
      title: '编辑企业',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/risk/approval/detail/:id',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/risk/approval_detail`
    ),
    name: 'Enterprise_Risk_Approval_Detail',
    meta: {
      title: '企业授信申请详情',
      roles: ['dealer']
    }
  },
  {
    path: '',
    name: 'CMS_Line',
    meta: {
      divider: true,
      title: '内容管理',
      icon: '',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/cms',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/page_edit'
    ),
    name: 'CMS_Page',
    meta: {
      title: '网站首页',
      icon: 'view_compact',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/material',
    name: 'CMS',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/material'
    ),
    meta: {
      title: '素材管理',
      icon: 'layers',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/material/edit/:id',
    name: 'CMS_Material',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/material_edit'
    ),
    meta: {
      title: '素材编辑',
      roles: ['dealer']
    }
  },
  {
    path: '',
    name: 'System_Line',
    meta: {
      divider: true,
      title: '系统配置',
      icon: '',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/system',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/system/index'
    ),
    name: 'System',
    meta: {
      title: '系统设置',
      icon: 'settings',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/system/catalog',
    name: 'System_Catalog',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/system/catalog'
    ),
    meta: {
      title: '服务目录',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/system/knowledge',
    name: 'System_Knowledge',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/system/knowledge'
    ),
    meta: {
      title: '知识目录',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/system/workflow',
    name: 'System_Workflow',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/system/workflow'
    ),
    meta: {
      title: '服务流程',
      roles: ['dealer']
    },
    props: true,
    children: [
      {
        path: 'process',
        name: 'System_Workflow_Process_Edit',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/system/workflow/process'
        ),
        meta: {
          title: '流程管理',
          roles: ['dealer']
        }
      },
      {
        path: 'task',
        name: 'System_Workflow_Task_Edit',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/system/workflow/task'
        ),
        meta: {
          title: '任务管理',
          roles: ['dealer']
        }
      },
    ]
  },
  {
    path: '/dealer/system/logs',
    name: 'System_Logs',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/system/logs'
    ),
    meta: {
      title: '系统日志',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/system/bugs',
    name: 'System_Bugs',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/system/bugs'
    ),
    meta: {
      title: '问题反馈',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/system/release',
    name: 'System_Release',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/system/release'
    ),
    meta: {
      title: '发行说明',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/system/tools',
    name: 'System_Tools',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/system/tools'
    ),
    meta: {
      title: '管理工具',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/system/organize',
    name: 'System_Organize',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/system/organize'
    ),
    meta: {
      title: '组织权限',
      roles: ['dealer']
    },
    props: true,
    children: [
      {
        path: 'department',
        name: 'System_Organize_Dept_Edit',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/system/organize/department'
        ),
        meta: {
          title: '部门管理',
          roles: ['dealer']
        }
      },
      {
        path: 'roles',
        name: 'System_Organize_Roles_Edit',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/system/organize/roles'
        ),
        meta: {
          title: '角色管理',
          roles: ['dealer']
        }
      },
    ]
  },
  {
    path: '/dealer/system/product',
    name: 'System_Product',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/system/product'
    ),
    meta: {
      title: '产品管理',
      roles: ['dealer']
    },
    props: true,
    children: [
      {
        path: 'type',
        name: 'System_Product_Type_Edit',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/system/product/type'
        ),
        meta: {
          title: '产品类别',
          roles: ['dealer']
        }
      },
      {
        path: 'vendor',
        name: 'System_Product_Vendor_Edit',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/system/product/vendor'
        ),
        meta: {
          title: '供应商管理',
          roles: ['dealer']
        }
      },
      {
        path: 'list',
        name: 'System_Product_List_Edit',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/system/product/list'
        ),
        meta: {
          title: '供应商管理',
          roles: ['dealer']
        }
      }
    ]
  },
  {
    path: '/dealer/system/risk',
    name: 'System_RiskAdmin',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/system/risk'
    ),
    meta: {
      title: '风控管理',
      roles: ['dealer']
    },
    props: true,
    children: [
      {
        path: 'enterprise',
        name: 'System_RiskAdmin_Enterprise_List_Edit',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/system/risk/enterprise'
        ),
        meta: {
          title: '授信管理',
          roles: ['dealer']
        }
      },
      {
        path: 'model',
        name: 'System_RiskAdmin_Model_List_Edit',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/system/risk/model'
        ),
        meta: {
          title: '模型管理',
          roles: ['dealer']
        }
      },
      {
        path: 'citylevel',
        name: 'System_RiskAdmin_CityLevel_List_Edit',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/system/risk/citylevel'
        ),
        meta: {
          title: '区域管理',
          roles: ['dealer']
        }
      },
      {
        path: 'economy',
        name: 'System_RiskAdmin_Economy_List_Edit',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/system/risk/economy'
        ),
        meta: {
          title: '经济数据管理',
          roles: ['dealer']
        }
      },
      {
        path: 'enterprisetype',
        name: 'System_RiskAdmin_Enterprise_Type_List_Edit',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/system/risk/enterprisetype'
        ),
        meta: {
          title: '授信类型管理',
          roles: ['dealer']
        }
      },
    ]
  },
  {
    path: '/dealer/system/risk/model/edit/:id',
    name: 'System_RiskAdmin_Model_Create',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/system/risk/model_edit`
    ),
    meta: {
      title: '创建模型',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/system/risk/enterprise/detail/:id',
    name: 'System_RiskAdmin_Model_EnterpriseDetail',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/system/risk/enterprise_detail`
    ),
    meta: {
      title: '风控计算详情',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/system/risk/enterprise/acctscan/:name',
    name: 'System_RiskAdmin_Model_EnterpriseAcctScan',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/system/risk/enterprise_acctscan`
    ),
    meta: {
      title: '准入尽职调查结果',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/system/asset',
    name: 'System_Asset',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/system/asset'
    ),
    meta: {
      title: '资产配置',
      roles: ['dealer']
    },
    props: true,
    children: [
      {
        path: 'warehouse',
        name: 'System_Asset_WH_Edit',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/system/asset/warehouse'
        ),
        meta: {
          title: '库房管理',
          roles: ['dealer']
        }
      },

      {
        path: 'state',
        name: 'System_Asset_State_Edit',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/system/asset/state'
        ),
        meta: {
          title: '资产状态',
          roles: ['dealer']
        }
      },
    ]
  },
  {
    path: '/dealer/system/branch',
    name: 'System_Branch',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/system/branch'
    ),
    meta: {
      title: '分支机构',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/system/fields',
    name: 'System_Fields',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/system/fields'
    ),
    meta: {
      title: '自定义字段',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/system/rent',
    name: 'Lease_Admin',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/system/rent'
    ),
    meta: {
      title: '租赁管理',
      roles: ['dealer']
    },
    props: true,
    children: [
      {
        path: 'parameter',
        name: 'Lease_Admin_Parameter_Edit',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/system/rent/parameter'
        ),
        meta: {
          title: '默认参数',
          roles: ['dealer']
        }
      },
      {
        path: 'template',
        name: 'Lease_Admin_Template_Edit',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/system/rent/template'
        ),
        meta: {
          title: '报价模版',
          roles: ['dealer']
        }
      },
      {
        path: 'industry',
        name: 'Lease_Admin_Industry_Edit',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/dealer/system/rent/industry'
        ),
        meta: {
          title: '行业字典',
          roles: ['dealer']
        }
      },
    ]
  },
  {
    path: '/mobile',
    hidden: true,
    name: 'Mobile',
    meta: {
      isMobile: true,
      title: 'ESP移动端',
      breadcrumb: false,
      roles: ['dealer']
    },
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/mobile.vue`
    )
  },
  {
    path: '/dealer/creditline/apply',
    hidden: true,
    name: 'Mobile_Creditline_Apply',
    meta: {
      isMobile: true,
      title: '授信审批流程',
      breadcrumb: false,
      roles: ['dealer']
    },
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/creditline/apply`
    )
  },
  {
    path: '/dealer/creditline/preapply',
    hidden: true,
    name: 'Mobile_Creditline_PreApply',
    meta: {
      isMobile: true,
      title: '预授信',
      breadcrumb: false,
      roles: ['dealer']
    },
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/creditline/preapply`
    )
  },
  {
    path: '/dealer/creditline/query',
    hidden: true,
    name: 'Mobile_Creditline_Query',
    meta: {
      isMobile: true,
      title: '企业授信卡',
      breadcrumb: false,
      roles: ['dealer']
    },
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/creditline/query`
    )
  },
  {
    path: '/dealer/creditline/review',
    hidden: true,
    name: 'Mobile_Approval_Review',
    meta: {
      isMobile: true,
      title: '授信审批待审核',
      breadcrumb: false,
      roles: ['dealer']
    },
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/creditline/review`
    )
  },
  {
    path: '/dealer/creditline/review/detail/:id',
    hidden: true,
    name: 'Mobile_Approval_Review_Detail',
    meta: {
      isMobile: true,
      title: '授信审批流程详情',
      breadcrumb: false,
      roles: ['dealer']
    },
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/dealer/creditline/review_detail`
    )
  },
]
