<template>
  <v-app-bar app clipped-left color="primary" dark>
    <v-img alt="Logo" class="shrink mr-2" contain src="/static/logo.png" transition="scale-transition" max-width="40"></v-img>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn text @click="logout">退出</v-btn>
  </v-app-bar>
</template>
<script>
import {
  LOGOUT,
} from '@/store/actions.type'
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({
  }),
  created() {
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT).then(() => {
        location.reload()
      })
    },
  }
};
</script>
